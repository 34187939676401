<template>
  <v-container fluid>
    <layout :back="true" />
    <v-form ref="form" v-model="formValid" lazy-validation>
      <div class="mx-4">
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <h3 class="mt-4 font-weight-black">{{ $t("offer.edit") }}</h3>
          </v-col>
        </v-row>
        <!-- <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
            <v-subheader class="pa-0 ma-0 text-caption primary--text"
              ><span class="red--text text-subtitle-2">*</span
              >{{ $t("offer.language_publication") }}</v-subheader
            >
            <v-select
              v-model="newOffer.publish_language"
              :items="languages"
              item-value="id"
              item-text="name"
              hide-details="auto"
              :rules="required"
              :label="$t('offer.offer_language')"
              outlined
            ></v-select>
          </v-col>
        </v-row> -->
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text"
              >{{ $t("offer.name") }}<span class="red--text text-subtitle-2">*</span
              ></v-subheader
            >
            <v-select
              v-model="offerDetail.position"
              :items="positions"
              @change="updateTitle"
              item-value="id"
              item-text="name"
              hide-details="auto"
              :rules="required"
              :label="$t('offer.name')"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text"
              >{{ $t("offer.required_title") }}<span class="red--text text-subtitle-2">*</span
              ></v-subheader
            >
            <!-- Disable? -->
            <v-select
              v-model="offerDetail.title"
              disabled
              :items="titles"
              item-value="id"
              item-text="name"
              hide-details="auto"
              :rules="required"
              :label="$t('offer.required_title')"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text">{{
              $t("field.specialization")
            }}<span class="black--text pa-1">{{ $t("validation.opt") }}</span
              ></v-subheader>
            <v-select
              v-model="offerDetail.expertise"
              :items="expertises"
              item-value="id"
              item-text="name"
              hide-details="auto"
              :label="$t('field.specialization')"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text"
              >{{ $t("offer.contract_type") }}<span class="red--text text-subtitle-2">*</span
              ></v-subheader
            >
            <v-select
              v-model="offerDetail.contract_type"
              :items="contractTypes"
              item-value="id"
              item-text="name"
              hide-details="auto"
              :rules="required"
              :label="$t('offer.contract_type')"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text"
              >{{ $t("offer.working_type") }}<span class="red--text text-subtitle-2">*</span
              ></v-subheader
            >
            <v-select
              v-model="offerDetail.working_day"
              :items="workingDays"
              multiple
              item-value="id"
              item-text="name"
              hide-details="auto"
              :rules="required_boolean"
              :label="$t('offer.working_type')"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
            <v-subheader class="pa-0 text-caption primary--text">
              {{ $t("field.working_hours") }}<span class="black--text pa-1">{{ $t("validation.opt") }}</span
              ></v-subheader
            >
            <v-select
              :items="workingHours"
              v-model="offerDetail.working_hours"
              item-value="[0]"
              item-text="[1]"
              hide-details="auto"
              :label="$t('field.working_hours')"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0 mt-6">
            <v-textarea
              :label="$t('field.working_hours_comment')"
              auto-grow
              outlined
              hide-details
              maxlength="100"
              v-model="offerDetail.working_hours_comment"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text"
              >{{ $t("offer.experience_required") }}<span class="red--text text-subtitle-2">*</span
              ></v-subheader
            >
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-radio-group
              class="pa-0 ma-0"
              v-model="offerDetail.experience_required"
              row
              :rules="required_boolean"
            >
              <v-radio :value="true">
                <template v-slot:label>
                  <div class="primary--text text-subtitle-1 font-weight-normal">
                    {{ $t("field.yes") }}
                  </div>
                </template></v-radio
              >
              <v-radio :value="false">
                <template v-slot:label>
                  <div class="primary--text text-subtitle-1 font-weight-normal">
                    {{ $t("field.no") }}
                  </div>
                </template></v-radio
              >
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
            <v-subheader class="pa-0 ma-0 text-caption primary--text">{{
              $t("field.experience_fields")
            }}<span class="black--text pa-1">{{ $t("validation.opt") }}</span
              ></v-subheader
            ><v-select
              :items="experienceFields"
              item-value="id"
              multiple
              item-text="name"
              v-model="offerDetail.experience_fields"
              hide-details="auto"
              :label="$t('field.experience_fields')"
              outlined
            ></v-select>
          </v-col>
        </v-row>

        <!-- <v-row class="pa-0 ma-0" justify-sm="center">
        <v-col cols="12" sm="8" md="4" class="pa-0">
          <v-subheader class="pa-0 text-caption primary--text"
            ><span class="red--text text-subtitle-2">*</span
            >{{ $t("offer.experience_time") }}</v-subheader
          >
          <v-text-field
            v-model="newOffer.experience_time"
            name="input-10-1"
            :label="$t('offer.experience_time')"
            hide-details="auto"
            :rules="required"
            outlined
          >
          </v-text-field>
        </v-col>
      </v-row> -->
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text">
              {{ $t("offer.required_language") }}<span class="red--text text-subtitle-2">*</span
              ></v-subheader
            >
            <v-select
              :items="requiredLanguages"
              v-model="required_languages"
              item-value="id"
              item-text="name"
              multiple
              hide-details="auto"
              :rules="languagesRequired"
              :label="$t('offer.required_language')"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
            <v-subheader class="pa-0 text-caption primary--text">{{
              $t("offer.opcional_language")
            }}<span class="black--text pa-1">{{ $t("validation.opt") }}</span
              ></v-subheader>
            <v-select
              :items="optionalLanguages"
              v-model="optional_languages"
              item-value="id"
              item-text="name"
              multiple
              hide-details="auto"
              :label="$t('offer.opcional_language')"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
            <v-subheader class="pa-0 text-caption primary--text">
              {{ $t("field.remuneration_model") }}<span class="red--text text-subtitle-2">*</span
              ></v-subheader
            >
            <v-select
              :items="compensationModel"
              v-model="offerDetail.remuneration_model"
              item-value="[0]"
              item-text="[1]"
              :rules="required_boolean"
              hide-details="auto"
              :label="$t('field.remuneration_model')"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="9" sm="6" md="3" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text">
              {{ $t("offer.range") }} <span class="black--text pa-1">{{ $t("validation.opt") }}</span
              ></v-subheader
            >
            <v-text-field
              name="input-10-1"
              :label="$t('offer.range_placeholder')"
              hide-details="auto"
              v-mask="'########'"
              outlined
              v-model="offerDetail.salary"
            >
            </v-text-field>
          </v-col>
          <v-col cols="3" sm="2" md="1" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text">
              {{ $t("offer.currency") }}</v-subheader
            >
            <v-select
              :items="coin"
              v-model="currency"
              hide-details
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text"
              >{{ $t("field.incentives") }}<span class="red--text text-subtitle-2">*</span
              ></v-subheader
            >
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-radio-group
              class="pa-0 ma-0"
              v-model="offerDetail.incentives"
              row
              :rules="required_boolean"
            >
              <v-radio :value="true">
                <template v-slot:label>
                  <div class="primary--text text-subtitle-1 font-weight-normal">
                    {{ $t("field.yes") }}
                  </div>
                </template></v-radio
              >
              <v-radio :value="false">
                <template v-slot:label>
                  <div class="primary--text text-subtitle-1 font-weight-normal">
                    {{ $t("field.no") }}
                  </div>
                </template></v-radio
              >
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-checkbox
              v-model="offerDetail.must_be_near"
              hide-details="auto"
              class="pa-0"
            >
              <template #label>
                <div class="primary--text text-subtitle-1 ma-0">
                  {{ $t("field.must_be_near") }}
                </div>
              </template>
            </v-checkbox>
            <v-subheader class="pa-0 ma-0 text-caption primary--text">
              {{ $t("offer.must_be_near_desription") }}</v-subheader
            >
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text">
              {{ $t("field.incorporation_date") }}<span class="red--text text-subtitle-2">*</span
              ></v-subheader
            >
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="formatDate(offerDetail.incorporation_date)"
                  :label="$t('field.incorporation_date')"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  :rules="required"
                  outlined
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="offerDetail.incorporation_date"
                show-adjacent-months
                :min="todayDate"
                @input="menu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </div>
    </v-form>
    <v-row class="mx-2 my-8" justify="center">
      <v-col cols="6" sm="4" md="2" class="pa-0 mt-2 mr-0 text-center">
        <div>
          <v-btn
            color="transparent"
            large
            rounded
            class="customButton primary--text"
            style="text-transform: none; font-size: 15px"
            @click="$router.go(-1)"
          >
            {{ $t("offer.cancel") }}
          </v-btn>
        </div>
      </v-col>
      <v-col cols="6" sm="4" md="2" class="pa-0 mt-2 text-center">
        <div>
          <v-btn
            color="secondary"
            large
            rounded
            @click="changeOffer"
            style="text-transform: none; font-size: 15px"
          >
            {{ $t("operation.save") }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
// Layout component
import layout from "@/components/layouts/Profile.vue";
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    layout
  },
  data() {
    return {
      showConfirmPay: false,
      showLeaveModal: false,
      offerLeave: false,
      formValid: false,
      menu: false,
      offerDetail: {},
      optional_languages: [],
      required_languages: [],
      experienceField: [],
      required: [v => !!v || this.$i18n.t("validation.required")],
      required_boolean: [v => v != null || this.$i18n.t("validation.required")],
      languagesRequired: [
        v => v.length != 0 || this.$i18n.t("validation.required")
      ],
      currency: "€",
      coin: ["€", "$", "£"]
    };
  },
  async created() {
    this.enableLoading();
    await this.getOfferById(this.$route.params.offer_id);
    this.offerDetail = _.cloneDeep(this.offer);
    this.getPositions();
    this.getTitles();
    await this.getLanguages();
    await this.defaultLanguage();
    this.getExpertises();
    this.getWorkingDays();
    this.getContractTypes();
    this.getExperienceFields();
    await this.getWorkingHours();
    await this.getCompensationModel();
    this.refactorData();

    this.disableLoading();
  },
  computed: {
    ...mapGetters({ offer: "offer/offer" }),
    ...mapGetters({ positions: "core/positions" }),
    ...mapGetters({ titles: "core/titles" }),
    ...mapGetters({ workingDays: "core/workingDays" }),
    ...mapGetters({ expertises: "core/expertises" }),
    ...mapGetters({ languages: "core/languages" }),
    ...mapGetters({ contractTypes: "core/contractTypes" }),
    ...mapGetters({ experienceFields: "core/experienceFields" }),
    ...mapGetters({ childId: "user/childId" }),
    ...mapGetters({ workingHours: "core/workingHours" }),
    ...mapGetters({ compensationModel: "core/compensationModel" }),
    optionalLanguages() {
      return this.languages.filter(
        elm => !this.required_languages.includes(elm.id)
      );
    },
    requiredLanguages() {
      return this.languages.filter(
        elm => !this.optional_languages.includes(elm.id)
      );
    },
    todayDate() {
      return this.$moment().format("YYYY-MM-DD");
    }
  },
  methods: {
    ...mapActions({ getOfferById: "offer/getOfferById" }),
    ...mapActions({ updateOffer: "offer/updateOffer" }),
    ...mapActions({ getPositions: "core/getPositions" }),
    ...mapActions({ getTitles: "core/getTitles" }),
    ...mapActions({ getWorkingDays: "core/getWorkingDays" }),
    ...mapActions({ getExpertises: "core/getExpertises" }),
    ...mapActions({ getLanguages: "core/getLanguages" }),
    ...mapActions({ getExperienceFields: "core/getExperienceFields" }),
    ...mapActions({ getWorkingHours: "core/getWorkingHours" }),
    ...mapActions({ getCompensationModel: "core/getCompensationModel" }),
    ...mapActions({ getContractTypes: "core/getContractTypes" }),
    ...mapActions({ enableLoading: "application/enableLoading" }),
    ...mapActions({ disableLoading: "application/disableLoading" }),
    refactorLanguages() {
      this.offerDetail.languages;
      this.required_languages.map(r => {
        this.offerDetail.offer_languages.push({
          language: r,
          required: true,
          offer: null
        });
      });
      this.optional_languages.map(r => {
        this.offerDetail.offer_languages.push({
          language: r,
          required: false,
          offer: null
        });
      });
    },
    defaultLanguage() {
      this.offerDetail.offer_languages.map(l => {
        if (l.required) {
          this.required_languages.push(
            this.languages.find(f => f.name == l.language).id
          );
        } else {
          this.optional_languages.push(
            this.languages.find(f => f.name == l.language).id
          );
        }
      });
    },
    refactorData() {
      this.offerDetail.pharmacy = this.childId;
      this.offerDetail.position = this.offerDetail.position.id;
      this.offerDetail.title = this.offerDetail.title.id;
      if (this.offerDetail.expertise != null)
        this.offerDetail.expertise = this.offerDetail.expertise.id;
      this.offerDetail.contract_type = this.offerDetail.contract_type.id;
      this.offerDetail.working_day = this.offerDetail.working_day.map(function(elem) {return elem.id});
      this.offerDetail.offer_languages = [];
      this.offerDetail.remuneration_model = this.compensationModel.filter(r => {
        return r[1] == this.offerDetail.remuneration_model;
      })[0][0];
      if (this.offerDetail.working_hours != null)
        this.offerDetail.working_hours = this.workingHours.filter(w => {
          return w[1] == this.offerDetail.working_hours;
        })[0][0];
    },
    async changeOffer() {
      if (this.$refs.form.validate()) {
        await this.refactorLanguages();
        this.enableLoading();
        try {
          await this.updateOffer(this.offerDetail);
          this.$notify({
            title: this.$i18n.t("notification.offer_edit"),
            type: "success"
          });
          this.$router.go(-1);
        } catch {
          this.$notify({
            title: this.$i18n.t("notification.error_offer_edit"),
            type: "error"
          });
        } finally {
          this.disableLoading();
        }

        this.no;
      }
    },
    updateTitle() {
      this.offerDetail.title = this.positions.find(
        elm => elm.id == this.offerDetail.position
      ).associated_title;
    },
    formatDate(date) {
      return date
        ? date
            .split("-")
            .reverse()
            .join("/")
        : null;
    }
  }
};
</script>
<style lang="scss" scoped>
.customButton {
  box-shadow: none;
}
.v-input--reverse .v-input__slot {
  flex-direction: row-reverse;
  justify-content: flex-end;
  .v-application--is-ltr & {
    .v-input--selection-controls__input {
      margin-right: 0;
      margin-left: 8px;
    }
  }
  .v-application--is-rtl & {
    .v-input--selection-controls__input {
      margin-left: 0;
      margin-right: 8px;
    }
  }
}
</style>
